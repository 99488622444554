<template>
  <div class="container-fluid top-menos">
    <CModalExtended
      :title="titleModal"
      color="dark"
      size="lg"
      class="modal-extended"
      @update:show="closeModal()"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="12">
          <CTextarea
            :label="$t('label.observation')"
            addLabelClasses="text-right required"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            :placeholder="$t('label.observation')"
            v-uppercase
            :is-valid="hasError($v.Observation)"
            :invalid-feedback="errorMessage($v.Observation)"
            v-model.trim="$v.Observation.$model"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="submit"
          :disabled="isSubmit"
        >
        <CIcon name="checkAlt"/>{{$t('button.accept')}}
      </CButton>
        <CButton 
          color="wipe" 
          class="d-flex align-items-center"
          @click="closeModal()"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import DispachesMassiveValidations from '@/_validations/yard-management/movements/dispachesMassiveValidations';

function data() {
  return {
    Observation: '',
    modalActive: false,
    isSubmit: false,
  };
}

async function submit() {
  try{
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.Observation.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    await this.$emit('Update', this.Observation, this.SelectedContainer.YardCargoId);
    this.closeModal();
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    
  }catch(e){
    this.$store.state.yardManagement.loading = false;
    this.isSubmit = false;
    this.$notify({
      group: "container",
      title: "¡Error!",
      text: e,
      type: "error",
    });
  }
}

function closeModal() {
  this.Observation = '';
  this.$v.$reset();
  this.$emit('Close');
}

function titleModal(){
  let title = this.SelectedContainer.ContainerCode ?? '';
  return this.$t('label.observation')+': '+title;
}

export default {
  name: 'modal-observation-container',
  props: { modal: Boolean, SelectedContainer: Object },
  data,
  mixins: [
    GeneralMixin, 
  ],
  validations: DispachesMassiveValidations,
  directives: UpperCase,
  methods: {
    submit,
    closeModal,
  },
  computed: {
    titleModal,
  },
  watch: {
    modal: async function(NewVal){
      this.modalActive = NewVal;
      if (NewVal) {
        this.Observation = this.SelectedContainer.Observation;
      }
    },
  },
  
};
</script>